<template>
  <div class="cmsSetting">
    <div class="subject">
      <p class="title">設定</p>
    </div>
    <div class="contentsWidth mx-auto mt-4">

      <b-form class="mt-4" autocomplete="off">
        <!-- errSpanClassはデフォルトを空にしたかったけど他に影響があるのでdammyクラスを渡す -->
        <v-form-name
          label="氏名"
          maxLength="50"
          :left.sync="sei"
          :right.sync="mei"
          :placeholders="{left: '例）山田' , right: '例）太郎'}"
          :isRequired="true"
          :errors="{left: errors.sei || '', right: errors.mei || ''}"
          :errSpanClass="'dammy'"
        >
        </v-form-name>
        <hr>

        <v-form-name
          label="ふりがな"
          maxLength="100"
          :left.sync="kana_sei"
          :right.sync="kana_mei"
          :placeholders="{left: '例）やまだ' , right: '例）たろう'}"
          :isRequired="true"
          :errors="{left: errors.kana_sei || '', right: errors.kana_mei || ''}"
          :errSpanClass="'dammy'"
        >
        </v-form-name>
        <hr>

        <div class="container-fluid itemWrap">
          <div class="row mt-2 inllineBlock">
            <div class="preEmail mb-2 mr-5">
              <label>現在のメールアドレス</label>
            </div>
            <div class="inllineBlock">
              <span>{{ currentEmail }}</span>
            </div>
          </div>
        </div>
        <b-form-checkbox
          v-model="changeEmail"
        >
          メールアドレスを変更する
        </b-form-checkbox>
        <v-form-text
          itemStyle="mt-2"
          label="メールアドレス"
          maxLength="256"
          type="email"
          labelGridClass="inputLabel"
          placeholder="例）○○○○○○○○@abcd.co.jp"
          :class="!changeEmail && 'grayscale'"
          :disabled="!changeEmail"
          :isRequired="true"
          :errMessage="errors.mail_address || []"
          v-model="mail_address"
        >
        </v-form-text>
        <hr>

        <b-form-checkbox
          v-model="changePassword"
        >
          パスワードを変更する
        </b-form-checkbox>
        <v-form-text
          itemStyle="mt-2 attention"
          label="パスワード"
          maxLength="256"
          type="password"
          autocomplete="new-password"
          labelGridClass="inputLabel"
          placeholder=""
          @check = 'passCheck'
          :class="!changePassword && 'grayscale'"
          :disabled="!changePassword"
          :errMessage="errors.password || []"
          :isRequired="true"
          v-model="password"
        >
        </v-form-text>
        <v-form-text
          itemStyle="mt-2"
          label="パスワード確認"
          maxLength="256"
          type="password"
          autocomplete="new-password"
          labelGridClass="inputLabel"
          placeholder=""
          @check = 'passCheck'
          :class="!changePassword && 'grayscale'"
          :disabled="!changePassword"
          :errMessage="errors.password_twice || []"
          :isRequired="true"
          v-model="password_twice"
        >
        </v-form-text>
        <hr>

        <v-form-text
          itemStyle="mt-2"
          label="所属機関"
          maxLength="100"
          labelGridClass="inputLabel"
          placeholder="例）xxxx大学 大学院工学研究科"
          :errMessage="errors.affiliated_institution || []"
          v-model="affiliated_institution"
        >
        </v-form-text>
        <hr>

        <v-form-text
          itemStyle="mt-2"
          label="役職"
          maxLength="50"
          labelGridClass="inputLabel"
          placeholder="例）教授"
          :errMessage="errors.position || []"
          v-model="position"
        >
        </v-form-text>
        <hr>

        <b-container fluid class="itemWrap permission">
          <b-row class="mt-2">
            <b-col sm="5" class="inputLabel align-items-start">
              <div class="badgeWrap"><div class="mr-2 badge badge-required">必須</div></div>
              <label class="bold">権限</label>
            </b-col>
            <b-col sm="8">
                <span class="" v-for="(item) in authes" v-bind:key="item.value">
                  {{authItems[item]}}
                </span>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </div>

    <div class="bottonArea mt-4 flex flexCenter">
      <b-button class="btn btn-lg mr-2 bold" to="/cms/top">トップに戻る</b-button>
      <b-button
        @click="store"
        variant="info"
        :disabled=!isSend
        class="btn btn save btn-primary bold"
      >保存</b-button>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
import api from '@/modules/api';

export default {
  name: 'cmsSettingView',
  components: {
  },
  data() {
    return {
      id: null,
      currentEmail: null,
      authItems: [],
    };
  },
  computed: {
    sei: {
      get() {
        return this.$store.state.cmsSetting.inputData.sei;
      },
      set(value) {
        this.$store.commit('cmsSetting/updateFamilyName', value);
        this.$store.dispatch('cmsSetting/checkMarks');
      },
    },
    mei: {
      get() {
        return this.$store.state.cmsSetting.inputData.mei;
      },
      set(value) {
        this.$store.commit('cmsSetting/updateFirstName', value);
        this.$store.dispatch('cmsSetting/checkMarks');
      },
    },
    kana_sei: {
      get() {
        return this.$store.state.cmsSetting.inputData.kana_sei;
      },
      set(value) {
        this.$store.commit('cmsSetting/updateFamilyNameKana', value);
        this.$store.dispatch('cmsSetting/checkMarks');
      },
    },
    kana_mei: {
      get() {
        return this.$store.state.cmsSetting.inputData.kana_mei;
      },
      set(value) {
        this.$store.commit('cmsSetting/updateFirstNameKana', value);
        this.$store.dispatch('cmsSetting/checkMarks');
      },
    },
    mail_address: {
      get() {
        return this.$store.state.cmsSetting.inputData.mail_address;
      },
      set(value) {
        this.$store.commit('cmsSetting/updateMail_address', value);
        this.$store.dispatch('cmsSetting/checkMarks');
      },
    },
    affiliated_institution: {
      get() {
        return this.$store.state.cmsSetting.inputData.affiliated_institution;
      },
      set(value) {
        this.$store.commit('cmsSetting/updateAffiliated_institution', value);
        this.$store.dispatch('cmsSetting/checkMarks');
      },
    },
    position: {
      get() {
        return this.$store.state.cmsSetting.inputData.position;
      },
      set(value) {
        this.$store.commit('cmsSetting/updatePosition', value);
        this.$store.dispatch('cmsSetting/checkMarks');
      },
    },
    password: {
      get() {
        return this.$store.state.cmsSetting.inputData.password;
      },
      set(value) {
        this.$store.commit('cmsSetting/updatePassword', value);
        this.$store.dispatch('cmsSetting/checkMarks');
      },
    },
    password_twice: {
      get() {
        return this.$store.state.cmsSetting.inputData.password_twice;
      },
      set(value) {
        this.$store.commit('cmsSetting/updatePassword_twice', value);
        this.$store.dispatch('cmsSetting/checkMarks');
      },
    },
    authes: {
      get() {
        return this.$store.state.cmsSetting.inputData.authes;
      },
    },
    changeEmail: {
      get() {
        return this.$store.state.cmsSetting.inputData.changeEmail;
      },
      set(value) {
        this.$store.commit('cmsSetting/isChangeEmail', value);
        this.$store.dispatch('cmsSetting/resetItem', { name: 'email', value });
      },
    },
    changePassword: {
      get() {
        return this.$store.state.cmsSetting.inputData.changePassword;
      },
      set(value) {
        this.$store.commit('cmsSetting/isChangePassword', value);
        this.$store.dispatch('cmsSetting/resetItem', { name: 'password', value });
      },
    },
    errors() {
      return this.$store.state.cmsSetting.errors;
    },
    isSend() {
      return this.$store.state.cmsSetting.isSend;
    },
  },
  methods: {
    // 権限
    async getAuth() {
      const auth = await api
        .send('/api/cms/setting/auth')
        .catch(() => false);
      if (auth) this.authItems = auth.data.authItems;
    },
    // 管理者情報
    async edit() {
      const items = await api
        .send('/api/cms/setting')
        .catch(() => false);
      if (items) this.$store.commit('cmsSetting/setDbData', items.data.edit);
      this.currentEmail = this.mail_address;
      this.$store.dispatch('page/offLoading');
    },
    // 更新
    async store() {
      this.$store.dispatch('page/onWaiting');
      const params = this.$store.state.cmsSetting.inputData;
      const res = await api
        .send('/api/cms/setting/store', params)
        .catch((error) => {
          this.$store.commit('cmsSetting/setErrorMsg', error.response.data.message);
          return false;
        });
      if (res !== false) {
        await this.alert('保存が完了しました。');
        // email変更時はログイン情報更新
        if (this.changeEmail) {
          this.currentEmail = this.mail_address;
          this.$store.dispatch('auth/authChange', this.mail_address);
        }
        this.$store.dispatch('cmsSetting/resetErrorMsg');
        this.$store.dispatch('cmsSetting/resetFlg');
        window.scrollTo(0, 0);
      }
      this.$store.dispatch('page/offWaiting');
    },
    passCheck() {
      this.$store.commit('cmsSetting/deleteErrMsg', 'password_twice');
      if (this.password !== this.password_twice) {
        this.$store.commit('cmsSetting/addErrorMsg', {
          key: 'password_twice',
          msg: ['パスワードの確認とパスワードが一致するよう入力してください。'],
        });
      }
    },
  },
  // ロード画面
  created() {
    this.$store.dispatch('page/onLoading');
    this.$store.dispatch('cmsSetting/resetErrorMsg');
    this.getAuth();
    this.edit();
  },
};
</script>

<style>
  .cmsSetting .contentsWidth {
    width: 1080px;
    background: #FFF;
    padding: 3rem;
  }
  .cmsSetting .subject {
    width: 1080px;
    border-bottom: .1rem solid #A0A9D0;
    margin: 3rem auto 0;
  }
  .cmsSetting .title {
    margin-bottom: 0;
  }
  .cmsSetting label {
    width: auto;
    display: contents;
  }
  .cmsSetting .inputLabel {
    justify-content: flex-start;
  }
  .cmsSetting form .row {
    margin: 0;
  }
  .cmsSetting input {
    font-size: 16px;
  }
  .cmsSetting input[type="email"] {
    width: 400px;
    height: 50px;
  }
  .cmsSetting input[type="checkbox"] + label {
    display: flex;
    align-items: center;
    min-height: 1.5rem;
    margin-left: 330px;
  }
  .cmsSetting .grayscale input {
    background: #F3F3F3;
    color: #F3F3F3;
  }
  .cmsSetting .grayscale input::placeholder {
    color: #F3F3F3 !important;
  }
  .cmsSetting .itemWrap .textWrap {
    padding-left: 15px;
  }
  .cmsSetting .preEmail,
  .cmsSetting .preEmail label {
    width: 265px;
    max-width: 265px;
    font-weight: bold;
    white-space: pre-line;
  }
  .cmsSetting .preEmail{
    padding-right: 0;
  }
  .cmsSetting .preEmail + div {
    padding: 0;
    word-break: break-word;
  }
  .cmsSetting .permission .inputLabel {
    max-width: 300px;
    padding-right: 0;
  }
  .cmsSetting .permission span {
    font-size: 16px;
  }
  .cmsSetting .permission span:not(:last-child)::after {
    content:"、";
  }
  .cmsSetting .attention::before {
    content: '英大文字・英小文字・数字・記号をそれぞれ1つ以上利用し8文字以上入力してください。';
    font-size: 13px;
    margin-left: 312px;
    width: 524px;
  }
  .cmsSetting .errItem .row.attention {
    margin-bottom: 0;
  }
  .cmsSetting .errItem input.form-control {
    border: solid 1px #dc3545 !important
  }
  .cmsSetting .itemWrap{
    width: 100%;
  }
</style>
